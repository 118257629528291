import {
  list as getAddressesInfo,
  update as updateMemberInfo,
  create as createMemberInfo,
} from '@wix/ambassador-addresses-api-v1-address/http';
import {ambassadorWithHeaders} from '../utils/ambassador.utils';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {MemberAddressesInfoModel} from '../models/MemberAddressesInfo.model';
import {ADDRESSES_APP_DEFINITION_ID} from '@wix/wixstores-client-core';
import {mapToUpdateMemberAddressRequest} from '../utils/billingDetails.utils';
import {AddressesFieldMasks} from '../../components/Checkout/constants';
import {ApiAddressFragment, FullAddressContactDetailsFragment} from '../../gql/graphql';

export class MemberService {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  public readonly currency?: string;
  public addressesInfo: MemberAddressesInfoModel = {
    addresses: [],
  };
  public isAddressesAppInstalled!: boolean;
  public currentUserEmail: string = '';

  constructor({siteStore, flowAPI, currency}: {flowAPI: ControllerFlowAPI; siteStore: SiteStore; currency?: string}) {
    this.siteStore = siteStore;
    this.flowAPI = flowAPI;
    this.currency = currency;
  }

  public isMember(): boolean {
    return Boolean(this.flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn);
  }

  public async fetchAddressesInfo(): Promise<void> {
    const {data} = await ambassadorWithHeaders(getAddressesInfo({}), this.siteStore, this.flowAPI, this.currency);
    this.addressesInfo = new MemberAddressesInfoModel(data);
  }

  public async setIsAddressesAppInstalled(): Promise<void> {
    this.isAddressesAppInstalled = await this.flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
      sectionId: 'my_addresses',
      appDefinitionId: ADDRESSES_APP_DEFINITION_ID,
    });
  }

  public async getCurrentUserEmail(): Promise<void> {
    this.currentUserEmail = await this.flowAPI.controllerConfig.wixCodeApi.user.currentUser.getEmail();
  }
  public async updateMemberInfo(
    contactDetails: FullAddressContactDetailsFragment,
    address: ApiAddressFragment,
    addressesServiceId: string
  ): Promise<void> {
    const mappedMemberDetailsInfo = mapToUpdateMemberAddressRequest(contactDetails, address, addressesServiceId);

    await ambassadorWithHeaders(
      updateMemberInfo({
        address: mappedMemberDetailsInfo,
        fieldMask: [
          AddressesFieldMasks.firstName,
          AddressesFieldMasks.lastName,
          AddressesFieldMasks.phoneNumber,
          AddressesFieldMasks.company,
          AddressesFieldMasks.addressLine1,
          AddressesFieldMasks.addressLine2,
          AddressesFieldMasks.streetName,
          AddressesFieldMasks.streetNumber,
          AddressesFieldMasks.country,
          AddressesFieldMasks.zipCode,
          AddressesFieldMasks.city,
          AddressesFieldMasks.subdivision,
          AddressesFieldMasks.taxId,
          AddressesFieldMasks.taxType,
        ],
        setAsDefault: false,
      }),
      this.siteStore,
      this.flowAPI,
      this.currency
    );
    await this.fetchAddressesInfo();
  }

  public async createMemberInfo(
    contactDetails: FullAddressContactDetailsFragment,
    address: ApiAddressFragment
  ): Promise<string> {
    const mappedMemberDetailsInfo = mapToUpdateMemberAddressRequest(contactDetails, address);

    const {data} = await ambassadorWithHeaders(
      createMemberInfo({
        address: mappedMemberDetailsInfo,
        setAsDefault: false,
      }),
      this.siteStore,
      this.flowAPI,
      this.currency
    );
    await this.fetchAddressesInfo();
    return data.id!;
  }
}
